import React from 'react';
import { connect } from 'react-redux';
import { Oo, ageByDateOfBirth, calcInches, cleanName, convertToImperial, convertToMetric, getAllTimeNumbersFromRecordAndStreak } from '../../helpers/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import AllTimeNumbers from '../parts/AllTimeNumbers';
import ResultModal from '../parts/ResultModal';
import { sportleDataActionCreators } from '../../stores/sportle-store';
import { bindActionCreators } from 'redux';
import { ref, set, update } from "firebase/database";
import { auth } from '../../firebase';
import moment from 'moment';
import Leaderboard from '../parts/leaderboard';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from '@mui/material';
import { Columns, Sport } from '../../helpers/sport-enum';
import GuessBoard from '../parts/guess-board';
import ShareLinks from './share-links';
import SelectTeam from '../parts/select-team';
import Selection from '../parts/selection';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IOS = "IOS";
const ANDROID = "ANDROID";
const WINDOWSPHONE = "WINDOWSPHONE";

const imperialLocalStorage = localStorage.getItem('imperial');

class SportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imperial: imperialLocalStorage === undefined || imperialLocalStorage !== 'false',
            operatingSystem: this.getMobileOperatingSystem(),
            guesses: [],
            guessedCorrect: {},
            modal: false,
            leaderboardModal: false,
            validPlayers: [],
            fullRoster: [],
            c: {},
            gameStartTime: new Date()
        }

        this.getData = this.getData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleLeaderboard = this.toggleLeaderboard.bind(this);
        this.getText = this.getText.bind(this);
        this.openModals = this.openModals.bind(this);
        this.toggleImperial = this.toggleImperial.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData(refresh) {
        if (this.props.loading) return;
        if (refresh) {
            this.props.load();
        }
        this.setState({ databaseError: false, gameStartTime: new Date(), loaded: false, guesses: [], allTimeRecord: "0-0", streak: 0 }, () => {
            let fullRoster = [];
            let conferences = this.state.conferences ?? [];
            let playersPromise;
            let conferencesPromise;
            let validPlayers = [];
            let userPrevGuesses;
            let firstGuessTime;
            if (this.props.team) {
                playersPromise = new Promise((res) => {
                    this.props.actions.getPlayersList(this.props.version, this.props.game.shorthand ?? this.props.game.name, this.props.game == Sport.All ? null : cleanName(this.props.team).replaceAll(" ", "")).then(() => {
                        if (!this.props.sportle.playersList.length) {
                            const displayTeamName = this.props.game == Sport.All ? '' : (this.props.team.split(" ").reduce((partial, val) => partial + val.charAt(0).toUpperCase() + val.slice(1) + " ", "").trim() + ': ');
                            toast.info(`${displayTeamName}No players available at the moment.`)
                        }
                        fullRoster = this.props.sportle.playersList;
                        validPlayers = this.props.sportle.playersList;
                        res();
                    });
                })
                if ([Sport.NFL, Sport.NBA, Sport.WNBA].includes(this.props.game) && (refresh || !this.state.conferences || !this.state.conferences.length)) {
                    conferencesPromise = new Promise((res) => {
                        this.props.actions.getConferencesList(this.props.version, this.props.game === Sport.WNBA ? Sport.NBA.name : this.props.game.shorthand ?? this.props.game.name).then(() => {
                            conferences = this.props.sportle.conferencesList;
                            res();
                        });
                    })
                } else {
                    conferencesPromise = Promise.resolve();
                }
                let alert = false;
                if (this.props.sportsGuesses) {
                    const obj = this.props.sportsGuesses[`${this.props.game.shorthand ?? this.props.game.name}-${this.props.team.replaceAll("/", "")}-guesses`];
                    userPrevGuesses = obj ?? null;
                    firstGuessTime = obj ? obj.start_time : null;
                    alert = userPrevGuesses && [Sport.NFL, Sport.NBA, Sport.MLB, Sport.NHL, Sport.Prem, Sport.LaLiga].includes(this.props.game);
                }
            }

            Promise.all([playersPromise, conferencesPromise]).then(() => {
                let c;
                if (this.props.team && validPlayers.length) {
                    c = Oo(new Date(), validPlayers);
                    c.c = true;
                    if (c.img) {
                        //preload image to prevent spinners later
                        const img = new Image();
                        img.src = c.img;
                    }
                    userPrevGuesses = this.setLoadedGuesses(userPrevGuesses, fullRoster);
                    if (!userPrevGuesses.length) {
                        firstGuessTime = null;
                    }
                    validPlayers = validPlayers.filter(p => !userPrevGuesses.map(g => g.name).includes(p.name));
                    const sportLeaderboard = this.props.sportsLeaderboards[this.props.game.shorthand ?? this.props.game.name];
                    if (sportLeaderboard) {
                        const teamLeaderboard = sportLeaderboard[this.props.team.replaceAll("/", "")];
                        if (teamLeaderboard) {
                            this.setLeaderboard(teamLeaderboard);
                        } else {
                            this.setState({ teamLeaderboard: null })
                        }
                    } else {
                        this.setState({ teamLeaderboard: null })
                    }
                }
                if (window.location.hostname === "localhost" && [Sport.NFL, Sport.NBA, Sport.WNBA].includes(this.props.game)) {
                    fullRoster.forEach(player => {
                        const conf = conferences.find(it => it.teams.includes(player.college));
                        if (!conf) {
                            console.log(player)
                            alert(player);
                            throw "Conference not found for player: " + player.college
                        }

                    })
                }
                firstGuessTime = !firstGuessTime ||
                    firstGuessTime == "null"
                    ? null
                    : firstGuessTime;
                this.setState({ validPlayers, fullRoster, conferences, firstGuessTime, guesses: userPrevGuesses ?? [], c, loaded: true }, () => {

                    const tableBody = document.getElementsByClassName("table-body");
                    if (tableBody && tableBody.length) {
                        if (!tableBody[0].scrollHeight) {
                            new Promise(resolve => setTimeout(resolve, 200)).then(() => {
                                tableBody[0].scrollTop = tableBody[0].scrollHeight;
                            })
                        } else {
                            tableBody[0].scrollTop = tableBody[0].scrollHeight;
                        }
                    }

                    if (!this.props.modal) {
                        this.openModals();
                    }
                })
            }).catch(e => {
                console.log(e);
                if (typeof e === 'object' && e.message && e.message.toLowerCase() === 'permission denied') {
                    this.setState({ databaseError: <p>Database error. Please <button className="btn btn-link" style={{ padding: '0px', verticalAlign: 'baseline', textDecoration: 'underline' }} type="button" onClick={this.getData}>try again</button>.<br />If the issue persists, please try logging out and logging back in.</p> })
                }
            });
        })
    }

    setLoadedGuesses(userPrevGuesses, fullRoster) {
        let gameEndDate = new Date(this.state.gameStartTime);
        gameEndDate.setDate(gameEndDate.getDate() + 1);
        gameEndDate.setHours(0, 0, 0, 0);
        if (!userPrevGuesses || !userPrevGuesses.expiry || new Date(userPrevGuesses.expiry).getTime() !== gameEndDate.getTime() || !userPrevGuesses.version || userPrevGuesses.version !== this.props.version) {
            return [];
        } else {
            let fullObjArr = [];
            fullRoster = fullRoster ?? this.state.fullRoster;
            userPrevGuesses.value.forEach(g => {
                const ref = fullRoster.find(p => p.name === g);
                if (ref) { fullObjArr.push(ref); }
            })
            return fullObjArr;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modal !== this.props.modal && !this.props.modal) {
            this.openModals();
        }

        if (prevProps.team !== this.props.team || (prevProps.loading && !this.props.loading)) {
            this.getData();
        }

        if (prevState.guesses.length !== this.state.guesses.length) {
            if ((this.state.guessedCorrect[this.props.team] || this.state.guesses.length >= this.props.maxGuesses) || this.state.modal) {
                this.setState({ text: this.getText() })
            }
        }

        if (JSON.stringify(prevProps.sportsGuesses) !== JSON.stringify(this.props.sportsGuesses)) {
            if (this.props.team && this.state.fullRoster.length && this.props.sportsGuesses) {
                const possibleUserPrevGuesses = this.props.sportsGuesses[`${this.props.game.shorthand ?? this.props.game.name}-${this.props.team.replaceAll("/", "")}-guesses`];
                if (possibleUserPrevGuesses && (!prevProps.sportsGuesses || !prevProps.sportsGuesses[`${this.props.game.shorthand ?? this.props.game.name}-${this.props.team.replaceAll("/", "")}-guesses`] || JSON.stringify(possibleUserPrevGuesses) !== JSON.stringify(prevProps.sportsGuesses[`${this.props.game.shorthand ?? this.props.game.name}-${this.props.team.replaceAll("/", "")}-guesses`]))) {
                    const userPrevGuesses = this.setLoadedGuesses(possibleUserPrevGuesses);
                    const validPlayers = this.state.fullRoster.filter(p => !userPrevGuesses.map(g => g.name).includes(p.name));

                    this.setState({ guesses: userPrevGuesses, validPlayers: validPlayers }, () => {
                        const tableBody = document.getElementsByClassName("table-body");
                        if (tableBody && tableBody.length) {
                            if (!tableBody[0].scrollHeight) {
                                new Promise(resolve => setTimeout(resolve, 200)).then(() => {
                                    tableBody[0].scrollTop = tableBody[0].scrollHeight;
                                })
                            } else {
                                tableBody[0].scrollTop = tableBody[0].scrollHeight;
                            }
                        }
                        if (!this.state.modal) { this.openModals(); }
                    })
                }
            }
        }
        if (JSON.stringify(prevProps.sportsLeaderboards) !== JSON.stringify(this.props.sportsLeaderboards)) {
            if (this.props.team && this.props.sportsLeaderboards) {
                const sportLeaderboard = this.props.sportsLeaderboards[this.props.game.shorthand ?? this.props.game.name];
                if (sportLeaderboard) {
                    const teamLeaderboard = sportLeaderboard[this.props.team.replaceAll("/", "")];
                    if (teamLeaderboard && (!prevProps.sportsLeaderboards || !prevProps.sportsLeaderboards[this.props.game.shorthand ?? this.props.game.name] || prevProps.sportsLeaderboards[this.props.game.shorthand ?? this.props.game.name] != sportLeaderboard || !prevProps.sportsLeaderboards[this.props.game.shorthand ?? this.props.game.name][this.props.team] || prevProps.sportsLeaderboards[this.props.game.shorthand ?? this.props.game.name][this.props.team] != teamLeaderboard)) {
                        this.setLeaderboard(teamLeaderboard, () => {
                            this.setState({ text: this.getText() })
                        });
                    } else {
                        this.setState({ teamLeaderboard: null })
                    }
                } else {
                    this.setState({ teamLeaderboard: null })
                }
            } else {
                this.setState({ teamLeaderboard: null })
            }
        }
    }

    setLeaderboard(leaderboard, callback) {
        this.setState({ teamLeaderboard: leaderboard })
        if (leaderboard != null) {
            leaderboard = leaderboard[auth.currentUser.uid];
            if (leaderboard) {
                var strk = 0;
                if (leaderboard['streak'] != null) {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    let tomorrow = new Date();
                    tomorrow.setDate(today.getDate() + 1);
                    tomorrow.setHours(0, 0, 0, 0);
                    var lastEntry = leaderboard['expiry'];
                    var lastEntryDate = new Date(lastEntry);
                    strk = lastEntryDate.getTime() === today.getTime() ||
                        lastEntryDate.getTime() === tomorrow.getTime()
                        ? parseInt(leaderboard['streak'])
                        : 0;
                }
                this.setState({
                    allTimeRecord: leaderboard['wins'] != null && leaderboard['losses'] != null
                        ? `${leaderboard['wins']}-${leaderboard['losses']}`
                        : "0-0",
                    streak: strk
                }, () => {
                    if (callback) { callback(); }
                });
            } else {
                this.setState({
                    allTimeRecord: "0-0",
                    streak: 0
                }, () => {
                    if (callback) { callback(); }
                });
            }
        } else {
            this.setState({
                allTimeRecord: "0-0",
                streak: 0
            }, () => {
                if (callback) { callback(); }
            });
        }
    }

    openModals() {
        if (this.state.guesses.length) {
            let guessedCorrect = { ...this.state.guessedCorrect }
            if (this.state.guesses.find((it, idx) => idx < this.props.maxGuesses && it.c)) {
                guessedCorrect[this.props.team] = true;
                this.setState({ guessedCorrect }, () => {
                    this.setState({ text: this.getText() }, () => {
                        this.setState({ modal: true })
                    })
                })
            } else if (this.state.guesses.length >= this.props.maxGuesses) {
                this.setState({ text: this.getText() })
                this.setState({ modal: true }, () => {
                    const tableBody = document.getElementsByClassName("table-body")[0];
                    if (tableBody) {
                        if (!tableBody.scrollHeight) {
                            new Promise(resolve => setTimeout(resolve, 200)).then(() => {
                                tableBody.scrollTop = tableBody.scrollHeight;
                            })
                        } else {
                            tableBody.scrollTop = tableBody.scrollHeight;
                        }
                    }
                })
            }
        } else if (!localStorage.getItem('visited-regular') && !localStorage.getItem(`visited-${(this.props.game.shorthand ?? this.props.game.name).replaceAll(" ", "").toLowerCase()}}`)) {
            this.props.toggleHelp();
        }

        localStorage.setItem('visited-regular', true);
    }

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return WINDOWSPHONE;
        }

        if (/android/i.test(userAgent)) {
            return ANDROID;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
            return IOS;
        }

        return "unknown";
    }

    onChange(player) {
        console.log(player);
        let gameEndDate = new Date(this.state.gameStartTime);
        gameEndDate.setDate(gameEndDate.getDate() + 1);
        gameEndDate.setHours(0, 0, 0, 0);
        if (new Date() > gameEndDate) {
            this.getData(true);
        } else {
            const playerObj = [...this.state.validPlayers].find(p => p.name === player);
            if (playerObj) {
                let validPlayers = [...this.state.validPlayers]
                validPlayers.splice(validPlayers.indexOf(playerObj), 1);
                let guesses = [...this.state.guesses];
                guesses.push(playerObj);
                const correct = this.state.c.name === playerObj.name;
                let date = new Date();
                date.setHours(0, 0, 0, 0)
                date.setDate(date.getDate() + 1);
                const expiry = moment(date).format("YYYY-MM-DD HH:mm:ss.SSSSSS")
                if (correct || guesses.length === this.props.maxGuesses) {
                    if (!correct) {
                        guesses.push(this.state.c);
                    }
                    const key = "leaderboards/" + (this.props.game.shorthand ?? this.props.game.name) + "/" + this.props.team.replaceAll("/", "") + "/" + auth.currentUser.uid;
                    var winsAndLosses = this.state.allTimeRecord.split("-");
                    const updates =
                    {
                        [key]: {
                            "correct": correct,
                            "numGuesses": correct ? guesses.length : null,
                            'expiry': expiry,
                            'version': this.props.version,
                            "game_duration": guesses.length == 1
                                ? 0
                                : parseInt(moment().diff(moment(this.state.firstGuessTime), 'seconds')),
                            "streak": correct ? this.state.streak + 1 : 0,
                            "wins": parseInt(winsAndLosses[0]) + (correct ? 1 : 0),
                            "losses":
                                parseInt(winsAndLosses[1]) + (!correct ? 1 : 0)
                        }
                    };
                    update(ref(this.props.database), updates);
                }
                let guessedCorrect = { ...this.state.guessedCorrect };
                guessedCorrect[this.props.team] = correct;
                this.setState({ guesses, validPlayers, guessedCorrect, modal: correct || guesses.length >= this.props.maxGuesses }, () => {
                    const tableBody = document.getElementsByClassName("table-body")[0];
                    if (tableBody) {
                        tableBody.scrollTop = tableBody.scrollHeight;
                        if (this.state.modal) {
                            new Promise(resolve => setTimeout(resolve, 200)).then(() => {
                                tableBody.scrollTop = tableBody.scrollHeight;
                            })
                        }
                    }
                })
                this.setState({ firstGuessTime: guesses.length === 1 ? moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS") : this.state.firstGuessTime }, () => {
                    set(ref(this.props.database, `guesses/${auth.currentUser?.uid}/${this.props.game.shorthand ?? this.props.game.name}-${this.props.team.replaceAll("/", "")}-guesses`), { value: guesses.map(g => g.name), expiry: expiry, version: this.props.version, start_time: this.state.firstGuessTime });
                });
            }
        }
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (!this.state.modal && this.state.guesses.length === this.props.maxGuesses && !this.state.guessedCorrect[this.props.team]) {
                const tableBody = document.getElementsByClassName("table-body")[0];
                if (tableBody) {
                    tableBody.scrollTop = tableBody.scrollHeight;
                }
            }
        });
    }

    toggleLeaderboard() {
        this.setState({
            leaderboardModal: !this.state.leaderboardModal
        })
    }

    getText() {
        const c = { ...this.state.c };
        let guesses = [...this.state.guesses];
        guesses = guesses.filter((it, idx) => idx < this.props.maxGuesses);
        let teamName = this.props.game == Sport.All ? null : this.props.Teams.find(t => t.name.toLowerCase() === this.props.team);
        if (teamName) {
            const teamNames = teamName.name.split(" ");
            teamName = "";
            teamNames.forEach(t => teamName += t.charAt(0).toUpperCase() + t.slice(1) + " ");
            teamName = teamName.trim();
        }
        let text = this.props.game == Sport.All ? 'SportleGames.com (All Sports) ' : `SportleGames.com (${this.props.game.name}${teamName ? ' - ' + teamName : ''}) `;
        text += this.state.guessedCorrect[this.props.team] ? guesses.length + '/' + this.props.maxGuesses : 'X/' + this.props.maxGuesses;
        text += '%0D%0A%0D%0A';
        const columns = Columns(this.props.game);
        guesses.forEach(guess => {
            columns.map(c => c.attr).forEach(attr => {
                if (!['weight', 'height', 'position', 'age', 'exp', 'experience'].includes(attr) && guess[attr] === c[attr]) {
                    text += "🟩";
                    return;
                } else if (attr === 'age') {
                    const guessAge = ageByDateOfBirth(guess);
                    const cAge = ageByDateOfBirth(c);
                    if (guessAge == cAge) {
                        text += "🟩";
                        return;
                    }
                    if (guessAge != null &&
                        cAge != null &&
                        !isNaN(guessAge) &&
                        !isNaN(cAge)) {
                        if (Math.abs(parseInt(guessAge) - parseInt(cAge)) <= 2) {
                            text += "🟨";
                            return;
                        }
                    }
                    text += "⬛️";
                    return;
                } else if (['number'].includes(attr) && guess[attr] && c[attr] && guess[attr] !== "--" && c[attr] !== "--" && (guess[attr] - c[attr] <= 2 && guess[attr] - c[attr] >= -2)) {
                    text += "🟨";
                    return;
                } else if (attr === 'weight') {
                    let guessWeight = guess[attr];
                    let cWeight = c[attr];

                    if (this.state.imperial) {
                        if (["Prem", "La Liga"].includes(guess.sport ?? this.props.game.shorthand ?? this.props.game.name) && guess[attr] !== "N/A") {
                            guessWeight = convertToImperial(guess[attr], attr);
                        }

                        if (["Prem", "La Liga"].includes(c.sport ?? this.props.game.shorthand ?? this.props.game.name) && c[attr] !== "N/A") {
                            cWeight = convertToImperial(c[attr], attr);
                        }
                    } else {
                        if (!["Prem", "La Liga"].includes(guess.sport ?? this.props.game.shorthand ?? this.props.game.name) && guess[attr] !== "N/A") {
                            guessWeight = convertToMetric(guess[attr], attr);
                        }

                        if (!["Prem", "La Liga"].includes(c.sport ?? this.props.game.shorthand ?? this.props.game.name) && c[attr] !== "N/A") {
                            cWeight = convertToMetric(c[attr], attr);
                        }
                    }

                    guessWeight = ['--', 'N/A'].includes(guessWeight) || !guessWeight ? guessWeight : parseInt(guessWeight);
                    cWeight = ['--', 'N/A'].includes(cWeight) || !cWeight ? cWeight : parseInt(cWeight);

                    if (guessWeight === cWeight) {
                        text += "🟩";
                    } else if ((this.state.imperial && guessWeight - cWeight <= 10 && guessWeight - cWeight >= -10) || (!this.state.imperial && guessWeight - cWeight <= 4.5 && guessWeight - cWeight >= -4.5)) {
                        text += "🟨";
                    } else {
                        text += "⬛️";
                    }

                    return;
                } else if (attr === 'height') {
                    let guessInches = guess[attr];
                    let cInches = c[attr];

                    if (this.state.imperial) {
                        if (["Prem", "La Liga"].includes(c.sport ?? this.props.game.shorthand ?? this.props.game.name) && guess[attr] !== "N/A") {
                            guessInches = calcInches(convertToImperial(guess[attr], attr));
                        } else if (guess[attr] !== "N/A") {
                            guessInches = calcInches(guess[attr], attr);
                        }

                        if (["Prem", "La Liga"].includes(c.sport ?? this.props.game.shorthand ?? this.props.game.name) && c[attr] !== "N/A") {
                            cInches = calcInches(convertToImperial(c[attr], attr));
                        } else if (c[attr] !== "N/A") {
                            cInches = calcInches(c[attr], attr);
                        }
                    } else {
                        if (!["Prem", "La Liga"].includes(c.sport ?? this.props.game.shorthand ?? this.props.game.name) && guess[attr] !== "N/A") {
                            guessInches = convertToMetric(guess[attr], attr).match(/\d+/);
                            if (guessInches.length) {
                                guessInches = guessInches[0];
                            }
                        }

                        if (!["Prem", "La Liga"].includes(c.sport ?? this.props.game.shorthand ?? this.props.game.name) && c[attr] !== "N/A") {
                            cInches = convertToMetric(c[attr], attr).match(/\d+/);
                            if (cInches.length) {
                                cInches = cInches[0];
                            }
                        }
                    }

                    if (guessInches === cInches) {
                        text += "🟩";
                    } else if ((this.state.imperial && guessInches - cInches <= 2 && guessInches - cInches >= -2) || (!this.state.imperial && guessInches - cInches <= 5 && guessInches - cInches >= -5)) {
                        text += "🟨";
                    } else {
                        text += "⬛️";
                    }

                    return;
                } else if (attr === 'position' && (c.sport === guess.sport || (["Prem", "La Liga", "MLS", "NWSL"].includes(c.sport) && ["Prem", "La Liga", "MLS", "NWSL"].includes(guess.sport)))) {
                    if (c[attr] === guess[attr]) {
                        text += "🟩";
                    } else if (c[attr].includes('/') || guess[attr].includes('/')) {
                        const cPositions = c[attr].split('/');
                        const guessPositions = guess[attr].split('/');
                        const intersection = cPositions.filter(position => guessPositions.includes(position));
                        if (intersection.length) {
                            text += '🟨';
                        } else {
                            text += "⬛️";
                        }
                    } else {
                        text += "⬛️";
                    }
                    return;
                } else if (attr === 'experience') {
                    const compareGuess = (guess['experience'] ?? guess['exp']) === 'R' ? 1 : (guess['experience'] ?? guess['exp']);
                    const compareC = (c['experience'] ?? c['exp']) === 'R' ? 1 : (c['experience'] ?? c['exp']);
                    if (compareGuess === compareC) {
                        text += "🟩";
                    } else if (compareGuess - compareC <= 2 && compareGuess - compareC >= -2) {
                        text += '🟨';
                    } else {
                        text += "⬛️";
                    }
                    return;
                } else if (attr === 'college') {
                    const gConference = this.state.conferences.find(conf => conf.teams.includes(guess[attr]));
                    const cConference = this.state.conferences.find(conf => conf.teams.includes(c[attr]));
                    if (gConference.name === cConference.name) {
                        text += '🟨';
                    } else {
                        text += "⬛️";
                    }
                } else {
                    text += "⬛️";
                }
            })
            text += "%0D%0A"
        })

        text += getAllTimeNumbersFromRecordAndStreak(this.state.allTimeRecord, this.state.streak);

        return text;
    }

    toggleImperial() {
        this.setState({ imperial: !this.state.imperial }, () => {
            localStorage.setItem('imperial', this.state.imperial)
            this.setState({ text: this.getText() })
        });
    }

    render() {
        const date = new Date();
        const { c } = this.state
        return (
            <>
                {this.state.modal &&
                    <ResultModal c={c} league={c.sport ?? this.props.game.shorthand ?? this.props.game.name} guessedCorrect={this.state.guessedCorrect[this.props.team]} text={this.state.text} IOS={IOS} ANDROID={ANDROID} operatingSystem={this.state.operatingSystem} toggleModal={this.toggleModal} streak={this.state.streak} allTimeRecord={this.state.allTimeRecord} />
                }
                {this.state.leaderboardModal &&
                    <Leaderboard canView={this.state.guessedCorrect[this.props.team] || this.state.guesses.length >= this.props.maxGuesses} users={this.props.users} leaderboard={this.state.teamLeaderboard} currentUser={auth.currentUser} team={this.props.game == Sport.All ? null : this.props.Teams.find(t => t.name.toLowerCase() === this.props.team?.toLowerCase())?.name ?? this.props.team} toggleModal={this.toggleLeaderboard} sport={this.props.game} />}
                <Button variant="text" className="btn btn-link help" onClick={this.props.toggleHelp} disabled={this.props.loading}>HOW TO PLAY</Button>
                <div className="game-icons">
                    {(this.state.guessedCorrect[this.props.team] || this.state.guesses.length >= this.props.maxGuesses) &&
                        <ShareLinks operatingSystem={this.state.operatingSystem} IOS={IOS} ANDROID={ANDROID} text={this.state.text} desktop />
                    }
                    {((this.props.game == Sport.All && this.props.showLeaderboard) || (this.props.game != Sport.All && this.props.team && Object.entries(this.props.showLeaderboardByTeam).find(([k, v]) => k.toLowerCase() === this.props.team && v))) && <IconButton className="trophy" onClick={this.toggleLeaderboard}><FontAwesomeIcon icon={faTrophy} className="leaderboard" size={"lg"} /></IconButton>}
                </div>
                <div className="board-container">
                    {this.props.game != Sport.All && <SelectTeam game={this.props.game} loaded={this.state.loaded && !this.props.loading} teams={this.props.Teams} team={this.props.team} onChange={this.props.adjustTeam} />}
                    {
                        this.state.databaseError ? this.state.databaseError :
                            <>
                                <Selection ignoreTeam={this.props.game == Sport.All} team={this.props.game == Sport.All ? null : this.props.team} teamColors={this.props.teamColors} maxGuesses={this.props.maxGuesses} loading={(!this.state.loaded && this.props.team) || this.props.loading} disabled={!this.state.loaded || !this.props.team || this.state.guessedCorrect[this.props.team] || this.state.guesses.length >= this.props.maxGuesses} guessNum={this.state.guesses.length + 1} c={this.state.guessedCorrect[this.props.team]} validPlayers={this.state.validPlayers} actionsAllowed={this.state.actionsAllowed} onChange={this.onChange} />
                                {
                                    this.state.guesses.length ?
                                        <GuessBoard game={this.props.game} c={c} imperial={this.state.imperial} toggleImperial={this.toggleImperial} conferences={this.state.conferences} guesses={this.state.guesses} maxGuesses={this.props.maxGuesses} />
                                        :
                                        null
                                }
                            </>
                    }
                </div>
                {(this.state.guessedCorrect[this.props.team] || this.state.guesses.length >= this.props.maxGuesses) &&
                    <ShareLinks operatingSystem={this.state.operatingSystem} IOS={IOS} ANDROID={ANDROID} text={this.state.text} mobile />
                }
                {this.props.team &&
                    <AllTimeNumbers bottom={true} streak={this.state.streak} allTimeRecord={this.state.allTimeRecord} />
                }
            </>
        );
    }
}
export default connect(
    (state) => {
        const { sportle } = state;
        return {
            sportle
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sportleDataActionCreators), dispatch)
        }
    }
)(SportContainer)